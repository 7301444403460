import { useCallback, useEffect, useState } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "storybook/components/tooltip";
import { Typography } from "storybook/components/typography";
import { Notification } from "storybook/components/notification";
import { WaitingGraphic } from "./waiting-graphic";
import { useFeatureFlags } from "context/admin-content";
import { Loading } from "components/loading";
import {
  Headings,
  RiskList,
  ThankYou,
  RiskSection,
  TooltipContainer,
  RiskSectionContainer,
  RiskListItemIncreased,
  RiskListItemDecreased,
  WaitingWrapper,
} from "./styles";
import { SPECIALIST_HOSTNAME } from "env-vars";

const IncreasedRiskTooltip = () => {
  return (
    <>
      <Typography theme="default" variant="h6" color="black">
        <b>Hereditary cancer</b>
      </Typography>
      <br />
      <Typography theme="default" variant="body1" color="black">
        Hereditary cancer is a type of cancer that runs in some families.
        Collaborate with our specialist to create a personalized cancer
        prevention plan tailored to your hereditary cancer risk.
      </Typography>
    </>
  );
};

type Props = {
  report: any;
  stacked?: boolean;
  error: boolean;
  refetchRiskReport: () => void;
};

export function RiskSummary({
  report,
  error,
  stacked = false,
  refetchRiskReport,
}: Props) {
  const handleTryAgain = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      refetchRiskReport();
    },
    [refetchRiskReport]
  );

  const [hasUpdatePendingApproval, setHasUpdatePendingApproval] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const { disablePatientApprovalForRemoteScreenings } = useFeatureFlags();

  useEffect(() => {
    const fetchPendingApprovalStatus = async () => {
      setLoading(true);

      const patientId = localStorage.getItem("ciq_id");
      const idToken = localStorage.getItem("id_token");

      try {
        const response = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}/risk_profiles/status`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const { has_update_pending_approval } = await response.json();
        setHasUpdatePendingApproval(has_update_pending_approval);
      } catch (error) {
        console.error("Error fetching risk profile status", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPendingApprovalStatus();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (
    !disablePatientApprovalForRemoteScreenings &&
    hasUpdatePendingApproval
  ) {
    return (
      <WaitingWrapper>
        <WaitingGraphic />
        <Typography theme="default" variant="body1" color="ciqBrand">
          Your results are awaiting approval
        </Typography>
      </WaitingWrapper>
    );
  }

  if (error && !report) {
    return (
      <Notification
        fullWidth
        theme="default"
        variant="warning"
        title="Error loading risk assessment"
      >
        <Typography theme="default" variant="body1" color="black">
          <a href="/" role="button" onClick={handleTryAgain}>
            Please try again
          </a>
        </Typography>
      </Notification>
    );
  }

  const {
    vitals,
    lifestyle,
    is_female,
    risk_scores,
    cancers_and_dx,
    tc8_mri_enabled,
    meets_criteria_hboc,
    meets_criteria_hnpcc,
    normalized_tc_scores,
    has_cancer: hasCancer,
    mri_screening_eligible,
    increased_risk_detected,
    lung_screening_eligible,
    colon_screening_eligible,
    breast_screening_eligible,
    reproductive_risk_factors,
    family_cancer_history_hash,
    cervical_screening_eligible,
    prostate_screening_eligible,
    has_family_cancer: hasFamilyCancer,
  } = report;

  const cancers: Array<any> = cancers_and_dx;
  const familyCancerHistory: Array<any> = family_cancer_history_hash;

  const hasExerciseRisk =
    lifestyle.exercise_days && lifestyle.exercise_total < 75;

  const hasSmokingRisk =
    lifestyle.current_smoker_risk_assessment || lifestyle.pack_years > 0;

  const hasVegDietaryRisk =
    lifestyle.veg_servings !== null &&
    lifestyle.veg_servings_s !== "More Than 4" &&
    lifestyle.veg_servings_s !== "N/A" &&
    lifestyle.veg_servings < 4;

  const hasRedMeatDietaryRisk = lifestyle.red_meat_servings > 6;

  const hasBmiRisk = vitals.bmi >= 25;

  const hasStandardOfCareScreeningRisk =
    (is_female &&
      (mri_screening_eligible ||
        breast_screening_eligible ||
        cervical_screening_eligible)) ||
    lung_screening_eligible ||
    colon_screening_eligible ||
    (!is_female && prostate_screening_eligible);

  const hasPersonalBreastCancerRisk =
    tc8_mri_enabled && risk_scores.tc.v8_lifetime > 0;

  const hasHereditaryCancerRisk = meets_criteria_hnpcc || meets_criteria_hboc;

  const hasIncreasedRisk =
    hasCancer ||
    hasBmiRisk ||
    hasSmokingRisk ||
    hasFamilyCancer ||
    hasExerciseRisk ||
    hasVegDietaryRisk ||
    hasRedMeatDietaryRisk ||
    hasHereditaryCancerRisk ||
    hasPersonalBreastCancerRisk ||
    hasStandardOfCareScreeningRisk ||
    lifestyle.exceeds_alcohol_guidelines;

  const hasDecreasedExerciseRisk =
    lifestyle.exercise_days !== null && lifestyle.exercise_total >= 75;

  const hasDecreasedSmokingRisk =
    lifestyle.current_smoker_risk_assessment !== null &&
    !lifestyle.current_smoker_risk_assessment &&
    !lifestyle.quit_smoking;

  const hasDecreasedAlcoholRisk =
    lifestyle.current_drinker_risk_assessment !== null &&
    !lifestyle.current_drinker_risk_assessment;

  const hasDecreasedVegDietaryRisk =
    lifestyle.veg_servings_s === "More Than 4" || lifestyle.veg_servings >= 4;

  const hasDecreasedRedMeatDietaryRisk =
    lifestyle.red_meat_servings !== null &&
    lifestyle.red_meat_servings >= 0 &&
    lifestyle.red_meat_servings <= 6;

  const hasDecreasedBmiRisk =
    vitals.bmi !== null && vitals.bmi >= 18.5 && vitals.bmi <= 24.9;

  const hasDecreasedRisk =
    reproductive_risk_factors.age_first_birth ||
    !increased_risk_detected ||
    hasDecreasedExerciseRisk ||
    hasDecreasedSmokingRisk ||
    hasDecreasedAlcoholRisk ||
    hasDecreasedVegDietaryRisk ||
    hasDecreasedRedMeatDietaryRisk ||
    hasDecreasedBmiRisk;

  return (
    <div className="risk-summary">
      <Headings>
        {increased_risk_detected ? (
          <Typography theme="default" variant="h5" component="h3" color="monza">
            <b>Increased Risk Detected</b>
          </Typography>
        ) : (
          <Typography theme="default" variant="h5" component="h3" color="apple">
            <b>Average Risk Detected</b>
          </Typography>
        )}
      </Headings>
      {increased_risk_detected ? (
        <>
          <ThankYou>
            <Typography theme="default" variant="body1" color="black">
              Thank you for completing the assessment!
            </Typography>
          </ThankYou>

          <Typography theme="default" variant="body1" color="black">
            <b>
              Based on your personal and family history, we recommend that you
              meet with our specialist to identify ways to manage and reduce
              your cancer risk.
            </b>
            <TooltipContainer>
              <Tooltip
                placement="left"
                theme="default"
                title={<IncreasedRiskTooltip />}
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </TooltipContainer>
          </Typography>
        </>
      ) : (
        <Typography theme="default" variant="body1" color="black">
          Bring these results to your next doctor's appointment to create a
          personalized cancer prevention plan.
        </Typography>
      )}
      <RiskSectionContainer $stacked={stacked}>
        <RiskSection>
          <Typography theme="default" variant="body1" color="ciqBrandDark">
            Factors <b>increasing</b> your cancer risk
          </Typography>
          {hasIncreasedRisk ? (
            <RiskList>
              {hasCancer &&
                cancers.map((cancer) => (
                  <RiskListItemIncreased key={cancer.type}>
                    {cancer.type} Cancer
                    {cancer.age > 0 && ` at age ${cancer.age}`}
                  </RiskListItemIncreased>
                ))}
              {hasFamilyCancer &&
                familyCancerHistory.map((familyCancer, index) => (
                  <RiskListItemIncreased key={index}>
                    {`${familyCancer.relationship} had ${
                      familyCancer.cancer_site
                    } Cancer ${
                      familyCancer.age_of_dx &&
                      `at age ${familyCancer.age_of_dx}`
                    }`}
                  </RiskListItemIncreased>
                ))}
              {hasExerciseRisk && (
                <RiskListItemIncreased>
                  Total exercise of {lifestyle.exercise_total} minutes/week
                  below the recommended 75 minutes/week
                </RiskListItemIncreased>
              )}
              {hasSmokingRisk && (
                <RiskListItemIncreased>
                  {lifestyle.pack_years > 0
                    ? `Smoker: has smoked for ${lifestyle.pack_years} pack years`
                    : "Smoker"}
                </RiskListItemIncreased>
              )}
              {lifestyle.exceeds_alcohol_guidelines && (
                <RiskListItemIncreased>
                  {`Drinking exceeds the recommended maximum of ${lifestyle.recommended_drinks} drinks per week`}
                </RiskListItemIncreased>
              )}
              {hasVegDietaryRisk && (
                <RiskListItemIncreased>
                  {`Fruit/Veg: only ${lifestyle.veg_servings} servings perday`}
                </RiskListItemIncreased>
              )}
              {hasRedMeatDietaryRisk && (
                <RiskListItemIncreased>
                  {`Red Meat: ${lifestyle.red_meat_servings} servings per week`}
                </RiskListItemIncreased>
              )}
              {hasBmiRisk && (
                <RiskListItemIncreased>
                  {`BMI: ${vitals.bmi}`}
                </RiskListItemIncreased>
              )}
              {is_female && mri_screening_eligible && (
                <RiskListItemIncreased>
                  You're overdue for an MRI
                </RiskListItemIncreased>
              )}
              {is_female && cervical_screening_eligible && (
                <RiskListItemIncreased>
                  You're overdue for a Cervical Screening (Pap Smear)
                </RiskListItemIncreased>
              )}
              {is_female && breast_screening_eligible && (
                <RiskListItemIncreased>
                  You're overdue for a Mammogram
                </RiskListItemIncreased>
              )}
              {lung_screening_eligible && (
                <RiskListItemIncreased>
                  You're overdue for a Low Dose Lung CT
                </RiskListItemIncreased>
              )}
              {colon_screening_eligible && (
                <RiskListItemIncreased>
                  You're overdue for a Colonoscopy
                </RiskListItemIncreased>
              )}
              {!is_female && prostate_screening_eligible && (
                <RiskListItemIncreased>
                  You have had no prior PSA Test or it has been &gt;1 year since
                  your last PSA Test
                </RiskListItemIncreased>
              )}
              {tc8_mri_enabled && risk_scores.tc.v8_lifetime > 0 && (
                <RiskListItemIncreased>
                  {`Tyrer-Cuzick 8 Lifetime: ${normalized_tc_scores.tc8_lifetime}`}
                </RiskListItemIncreased>
              )}
              {meets_criteria_hnpcc && (
                <RiskListItemIncreased>
                  Potential Hereditary Colorectal Cancer Mutation
                </RiskListItemIncreased>
              )}
              {meets_criteria_hboc && (
                <RiskListItemIncreased>
                  Potential Hereditary Breast and Ovarian Cancer Syndrome
                  Mutation
                </RiskListItemIncreased>
              )}
            </RiskList>
          ) : (
            <p>N/A</p>
          )}
        </RiskSection>
        <RiskSection>
          <Typography theme="default" variant="body1" color="ciqBrandDark">
            Factors <b>decreasing</b> your cancer risk
          </Typography>
          {hasDecreasedRisk ? (
            <RiskList>
              {Boolean(reproductive_risk_factors.age_first_birth) && (
                <RiskListItemDecreased>
                  You have given birth
                </RiskListItemDecreased>
              )}
              {!increased_risk_detected && (
                <RiskListItemDecreased>
                  Up to date on recommended cancer screenings
                </RiskListItemDecreased>
              )}
              {hasDecreasedExerciseRisk && (
                <RiskListItemDecreased>
                  You are physically active
                </RiskListItemDecreased>
              )}
              {hasDecreasedSmokingRisk && (
                <RiskListItemDecreased>You do not smoke</RiskListItemDecreased>
              )}
              {hasDecreasedAlcoholRisk && (
                <RiskListItemDecreased>
                  You do not drink alcohol
                </RiskListItemDecreased>
              )}
              {lifestyle.veg_servings >= 4 && (
                <RiskListItemDecreased>
                  {`Fruit/Veg: ${lifestyle.veg_servings} servings per day`}
                </RiskListItemDecreased>
              )}
              {lifestyle.veg_servings_s === "More Than 4" && (
                <RiskListItemDecreased>
                  Fruit/Veg: More than 4 servings/day
                </RiskListItemDecreased>
              )}
              {hasDecreasedRedMeatDietaryRisk && (
                <RiskListItemDecreased>
                  {`Red Meat: ${lifestyle.red_meat_servings} servings per week`}
                </RiskListItemDecreased>
              )}
              {hasDecreasedBmiRisk && (
                <RiskListItemDecreased>
                  {`BMI: ${vitals.bmi}`}
                </RiskListItemDecreased>
              )}
            </RiskList>
          ) : (
            <p>N/A</p>
          )}
        </RiskSection>
      </RiskSectionContainer>
    </div>
  );
}
