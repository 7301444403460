import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { openQuestionnaire } from "helpers";
import { useIsTabActive } from "./use-is-tab-active";
import { VIRGIL_USER_URL, DELPHI_SURVEY_URL } from "env-vars";

type VirgilModuleData = {
  surveyName: string;
  surveyStatus: boolean;
  surveyToken: string;
};

type SurveyModule = {
  displayName: string;
  surveyStatus: boolean;
  surveyToken: string;
  surveyName: string;
  estimatedTime: number;
  showSummary: boolean;
  surveyDescription: string;
  moduleIcon: string;
  splashImage: string;
  splashTitle: string;
};

export enum LevelOfDone {
  DONE = "DONE",
  NOT_DONE = "NOT_DONE",
  EMPTY = "EMPTY",
}

type Props = {
  userId: string;
};

// I know there's some abiguity with the Survey context and this,
// but I wanted to keep long survey logic separate and we can see
// later how we should combine them, if we should.
export function useKioskLongSurvey({ userId }: Props) {
  const history = useHistory();
  const [error, setError] = useState<boolean>(false);
  const [levelOfDone, setLevelOfDone] = useState<LevelOfDone>();
  const [surveyModules, setSurveyModules] = useState<SurveyModule[]>();
  const [selectedSurvey, setSelectedSurvey] = useState<
    SurveyModule | undefined
  >(undefined);

  let newTab: boolean | null = null;
  let redirectUrl: string | null = null;
  let restartUrl: string | null = null;

  // redirect back to patient hub after completing a survey module in questionnaire
  redirectUrl = encodeURIComponent("/institution/patient-hub");

  // in kiosk mode, restart the survey by redirecting back to the kiosk landing page
  // presumably to clear the previous user's data
  restartUrl = encodeURIComponent("/institution");
  newTab = false;

  const { isTabVisible } = useIsTabActive();

  useEffect(() => {
    (async () => {
      if (!userId) return;

      const response = await fetch(`${VIRGIL_USER_URL}/${userId}`);

      if (!response.ok) {
        if (response.status === 404) {
          setLevelOfDone(LevelOfDone.EMPTY);

          return;
        }

        if (response.status === 401) {
          history.push("/login/passwordless");

          return;
        }

        setError(true);

        return;
      }

      const surveyGroupData = await response.json();

      const surveyGroups = surveyGroupData.user_survey_groups;
      const [surveyGroup] = surveyGroups;

      const virgilModuleData: Array<VirgilModuleData> = [];

      surveyGroup.surveys.forEach((survey: any) => {
        virgilModuleData.push({
          surveyName: survey.survey_name,
          surveyStatus: survey.survey_status,
          surveyToken: survey.survey_token,
        });
      });

      const promises: Array<Promise<any>> = [];

      surveyGroup.surveys.forEach(async (survey: any) => {
        promises.push(fetch(`${DELPHI_SURVEY_URL}/${survey.survey_id}`));
      });

      const surveyModules = await Promise.all(promises).then((results) =>
        Promise.all(results.map((r) => r.json()))
      );

      const surveyModulesData = surveyModules.map((survey, index) => {
        const delphiModuleData = {
          displayName: survey.title,
          estimatedTime: survey.data.estimated_minutes,
          moduleIcon: survey.data.module_icon,
          showSummary: survey.data.show_summary,
          splashImage: survey.data.splash_image,
          splashTitle: survey.data.splash_title,
          surveyDescription: survey.data.survey_description,
        };

        return {
          ...delphiModuleData,
          ...virgilModuleData[index],
        };
      });

      setSurveyModules(surveyModulesData);
    })();
  }, [history, userId, isTabVisible]);

  useEffect(() => {
    if (!surveyModules) return;

    if (!Array.isArray(surveyModules)) {
      setLevelOfDone(LevelOfDone.EMPTY);
    } else {
      surveyModules.every((survey) => survey.surveyStatus)
        ? setLevelOfDone(LevelOfDone.DONE)
        : setLevelOfDone(LevelOfDone.NOT_DONE);
    }

    const initialSelectedSurvey = surveyModules.find(
      (survey) => !survey.surveyStatus
    );

    setSelectedSurvey(initialSelectedSurvey);
  }, [surveyModules]);

  const handleContinue = useCallback(() => {
    // Remove PoC state
    localStorage.removeItem("pid");
    localStorage.removeItem("ciq-questionnaire.lastId");
    localStorage.removeItem("ciq-questionnaire.data");
    localStorage.removeItem("sessionId");

    const queryParams: { [key: string]: string } = {};

    if (redirectUrl) {
      queryParams.redirectUrl = redirectUrl;
    }

    if (restartUrl) {
      queryParams.restartUrl = restartUrl;
    }

    if (!selectedSurvey) {
      console.error("No selected module found");
      return;
    }

    openQuestionnaire({
      newTab,
      queryParams,
      context: "kiosk",
      token: selectedSurvey.surveyToken,
      skipLandingPage: false,
    });
  }, [newTab, redirectUrl, restartUrl, selectedSurvey]);

  return {
    error,
    levelOfDone,
    surveyModules,
    handleContinue,
    selectedSurvey,
    setSelectedSurvey,
  };
}
