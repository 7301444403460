export function DoctorIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="146" viewBox="0 0 150 146" fill="none">
            <g clip-path="url(#clip0_9816_15655)">
                <path d="M104.829 4.41441C133.962 16.634 153.023 46.8833 149.607 79.8922" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3.89 3.89" />
                <path d="M6.81932 96.3402C3.40238 86.4944 2.03315 75.7704 3.17496 64.7381C5.06107 46.5122 13.609 29.7431 27.2439 17.5198C34.9599 10.6023 43.941 5.45632 53.5932 2.31038" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3.89 3.89" />
                <path d="M102.547 141.134C94.283 144.279 85.4575 145.927 76.4632 145.927C73.9252 145.927 71.3509 145.794 68.8141 145.531C52.4144 143.834 37.8172 136.846 26.5449 126.438" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="3.89 3.89" />
                <path d="M22.6332 66.7514C25.7058 37.0616 52.2652 15.4842 81.9549 18.5572C111.645 21.6295 133.222 48.1892 130.149 77.8789C127.077 107.569 100.518 129.146 70.828 126.073C41.1383 123.001 19.5606 96.4411 22.6332 66.7514Z" fill="#ACE3E0" />
                <path d="M100.284 19.7183V3.68468C100.284 2.07618 98.9911 0.772243 97.3966 0.772243H63.0279C61.4334 0.772243 60.1409 2.07618 60.1409 3.68468V27.6993C60.1409 29.3078 61.4334 30.6117 63.0279 30.6117H64.2912L69.2208 35.5851C69.955 36.3255 71.21 35.8014 71.21 34.7538V30.6117H68.8788H78.7767" fill="white" />
                <path d="M100.284 19.7183V3.68468C100.284 2.07618 98.9911 0.772245 97.3966 0.772245H63.0279C61.4334 0.772245 60.1409 2.07618 60.1409 3.68468V27.6993C60.1409 29.3078 61.4334 30.6117 63.0279 30.6117H64.2912L69.2208 35.5851C69.955 36.3255 71.21 35.8014 71.21 34.7538V30.6117H68.8788H78.7767" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M79.7811 24.4296C79.7811 24.4296 76.3261 22.1675 73.7912 18.6447C72.1954 17.1028 71.5533 15.2424 71.3325 13.243C71.2227 12.658 71.1631 12.059 71.1631 11.448C71.1631 8.96636 73.1573 6.95431 75.6174 6.95431C77.5235 6.95431 79.1453 8.16434 79.7811 9.86231C80.4175 8.16434 82.0387 6.95431 83.9448 6.95431C86.4048 6.95431 88.3991 8.96636 88.3991 11.448C88.3991 12.0533 88.3318 12.6529 88.2087 13.243C86.5311 18.5698 83.8959 22.5425 79.7811 24.4296Z" fill="#F5D065" />
                <path d="M88.2087 13.2437C88.3318 12.6529 88.3991 12.0533 88.3991 11.448C88.3991 8.96637 86.4048 6.95432 83.9448 6.95432C82.0387 6.95432 80.4175 8.16435 79.7811 9.86232C79.1453 8.16435 77.5235 6.95432 75.6174 6.95432C73.1573 6.95432 71.1631 8.96637 71.1631 11.448C71.1631 12.059 71.2227 12.658 71.3325 13.243" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M73.7912 18.6447C76.3261 22.1675 79.7811 24.4296 79.7811 24.4296" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M64.2912 16.0584H74.0171L75.7709 11.7614L78.1332 18.3655L80.1751 13.6193L81.5368 16.0584H95.2024" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M82.474 39.3013H63.0279C61.4334 39.3013 60.1409 40.6052 60.1409 42.2137V66.2283C60.1409 67.8369 61.4334 69.1408 63.0279 69.1408H65.5863L70.5159 74.1141C71.25 74.8546 72.5051 74.3299 72.5051 73.2829V69.1408H70.0635H97.3966C98.9911 69.1408 100.284 67.8369 100.284 66.2283V50.2804" fill="white" />
                <path d="M82.474 39.3013H63.0279C61.4334 39.3013 60.1409 40.6052 60.1409 42.2137V66.2283C60.1409 67.8369 61.4334 69.1408 63.0279 69.1408H65.5863L70.5159 74.1141C71.25 74.8546 72.5051 74.3299 72.5051 73.2829V69.1408H70.0635H97.3966C98.9911 69.1408 100.284 67.8369 100.284 66.2283V50.2804" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M82.613 54.6719C82.613 56.2201 81.3687 57.4752 79.8344 57.4752C78.2995 57.4752 77.0552 56.2201 77.0552 54.6719C77.0552 53.1237 78.2995 51.8686 79.8344 51.8686C81.3687 51.8686 82.613 53.1237 82.613 54.6719Z" fill="#F19775" />
                <path d="M82.613 54.6719C82.613 56.2201 81.3687 57.4752 79.8344 57.4752C78.2995 57.4752 77.0552 56.2201 77.0552 54.6719C77.0552 53.1237 78.2995 51.8686 79.8344 51.8686C81.3687 51.8686 82.613 53.1237 82.613 54.6719Z" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M94.1104 54.6719C94.1104 56.2201 92.8668 57.4752 91.3319 57.4752C89.797 57.4752 88.5533 56.2201 88.5533 54.6719C88.5533 53.1237 89.797 51.8686 91.3319 51.8686C92.8668 51.8686 94.1104 53.1237 94.1104 54.6719Z" fill="#F19775" />
                <path d="M94.1104 54.6719C94.1104 56.2201 92.8668 57.4752 91.3319 57.4752C89.797 57.4752 88.5533 56.2201 88.5533 54.6719C88.5533 53.1237 89.797 51.8686 91.3319 51.8686C92.8668 51.8686 94.1104 53.1237 94.1104 54.6719Z" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M71.1154 54.6719C71.1154 56.2201 69.8711 57.4752 68.3362 57.4752C66.802 57.4752 65.5577 56.2201 65.5577 54.6719C65.5577 53.1237 66.802 51.8686 68.3362 51.8686C69.8711 51.8686 71.1154 53.1237 71.1154 54.6719Z" fill="#F19775" />
                <path d="M71.1154 54.6719C71.1154 56.2201 69.8711 57.4752 68.3362 57.4752C66.802 57.4752 65.5577 56.2201 65.5577 54.6719C65.5577 53.1237 66.802 51.8686 68.3362 51.8686C69.8711 51.8686 71.1154 53.1237 71.1154 54.6719Z" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M117.622 49.5578L112.692 54.5311C111.958 55.2716 110.703 54.7469 110.703 53.6999V49.5578" fill="white" />
                <path d="M117.622 49.5578L112.692 54.5311C111.958 55.2716 110.703 54.7469 110.703 53.6999V49.5578" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M113.338 49.5577H85.3611C83.7665 49.5577 82.474 48.2538 82.474 46.6453V22.6307C82.474 21.0222 83.7665 19.7183 85.3611 19.7183H119.73C121.324 19.7183 122.617 21.0222 122.617 22.6307V46.6453C122.617 48.2538 121.324 49.5577 119.73 49.5577H117.622" fill="white" />
                <path d="M113.338 49.5577H85.3611C83.7665 49.5577 82.474 48.2538 82.474 46.6453V22.6307C82.474 21.0222 83.7665 19.7183 85.3611 19.7183H119.73C121.324 19.7183 122.617 21.0222 122.617 22.6307V46.6453C122.617 48.2538 121.324 49.5577 119.73 49.5577H117.622" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M102.545 44.587V45.5635" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M97.6638 29.455C97.6638 26.7596 99.8497 24.5736 102.545 24.5736C105.241 24.5736 107.426 26.7596 107.426 29.455C107.426 30.6955 107.025 31.8865 106.202 32.6891C104.01 34.8243 102.545 34.3363 102.545 38.7297" fill="white" />
                <path d="M97.6638 29.455C97.6638 26.7596 99.8497 24.5736 102.545 24.5736C105.241 24.5736 107.426 26.7596 107.426 29.455C107.426 30.6955 107.025 31.8865 106.202 32.6891C104.01 34.8243 102.545 34.3363 102.545 38.7297" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M43.262 55.4804C44.8001 56.1359 46.184 57.1219 47.2728 58.4195C49.2804 60.8116 50.4923 63.8085 50.7062 66.6295C51.163 72.6613 50.0171 75.6213 50.0171 75.6213L50.4333 83.8795V97.4792C50.4333 99.1429 48.9625 100.491 47.1484 100.491H37.1498H26.2626H16.2638C14.4496 100.491 12.9789 99.1429 12.9789 97.4792V83.8795L13.4107 75.6213C13.4107 75.6213 12.1749 72.9081 12.7223 66.6289C12.9619 63.8802 14.0416 61.0718 15.818 58.7825C16.9623 57.3072 18.4727 56.1936 20.1671 55.4753L43.262 55.4804Z" fill="#F5D065" />
                <path d="M43.262 55.4804C44.8001 56.1359 46.184 57.1219 47.2728 58.4195C49.2804 60.8116 50.4923 63.8085 50.7062 66.6295C51.163 72.6613 50.0171 75.6213 50.0171 75.6213L50.4333 83.8795V97.4792C50.4333 99.1429 48.9625 100.491 47.1484 100.491H37.1498H26.2626H16.2638C14.4496 100.491 12.9789 99.1429 12.9789 97.4792V83.8795L13.4107 75.6213C13.4107 75.6213 12.1749 72.9081 12.7223 66.6289C12.9619 63.8802 14.0416 61.0718 15.818 58.7825C16.9623 57.3072 18.4727 56.1936 20.1671 55.4753L43.262 55.4804Z" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M0.00500488 121.69L0.0152198 121.536C0.402847 115.668 4.55094 110.572 10.5741 108.563L24.3241 103.976C25.4879 103.588 26.2627 102.574 26.2627 101.438V97.4987L37.1498 97.5044V101.444C37.1498 102.581 37.9249 103.595 39.0888 103.983L52.8344 108.564C58.8598 110.572 63.0095 115.669 63.3972 121.538L63.4074 121.69" fill="white" />
                <path d="M0.00500488 121.69L0.0152198 121.536C0.402847 115.668 4.55094 110.572 10.5741 108.563L24.3241 103.976C25.4879 103.588 26.2627 102.574 26.2627 101.438V97.4987L37.1498 97.5044V101.444C37.1498 102.581 37.9249 103.595 39.0888 103.983L52.8344 108.564C58.8598 110.572 63.0095 115.669 63.3972 121.538L63.4074 121.69" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.1027 84.1237H16.1236V75.4968H14.1027C11.7454 75.4968 9.83441 77.4073 9.83441 79.7645V79.8559C9.83441 82.2131 11.7454 84.1237 14.1027 84.1237Z" fill="white" />
                <path d="M14.1027 84.1237H16.1236V75.4968H14.1027C11.7454 75.4968 9.83441 77.4073 9.83441 79.7645V79.8559C9.83441 82.2131 11.7454 84.1237 14.1027 84.1237Z" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M47.3052 84.1237H49.3262C51.6834 84.1237 53.5946 82.2131 53.5946 79.8559V79.7645C53.5946 77.4073 51.6834 75.4968 49.3262 75.4968H47.3052V84.1237Z" fill="white" />
                <path d="M47.3052 84.1237H49.3262C51.6834 84.1237 53.5946 82.2131 53.5946 79.8559V79.7645C53.5946 77.4073 51.6834 75.4968 49.3262 75.4968H47.3052V84.1237Z" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M47.3052 75.4968C37.1843 80.1497 25.2137 73.9702 23.2055 67.0387C17.0748 69.7049 16.1235 75.4968 16.1235 75.4968V84.0996C16.1235 91.9962 23.1038 98.3978 31.7143 98.3978C40.3248 98.3978 47.3052 91.9962 47.3052 84.0996V75.4968Z" fill="white" />
                <path d="M47.3052 75.4968C37.1843 80.1497 25.2137 73.9702 23.2055 67.0387C17.0748 69.7049 16.1235 75.4968 16.1235 75.4968V84.0996C16.1235 91.9962 23.1038 98.3978 31.7143 98.3978C40.3248 98.3978 47.3052 91.9962 47.3052 84.0996V75.4968Z" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M47.3052 75.4968C47.3052 73.8414 46.9435 71.4429 45.7589 69.0914L47.3052 75.4968Z" fill="white" />
                <path d="M47.3052 75.4968C47.3052 73.8414 46.9435 71.4429 45.7589 69.0914" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M31.7143 119.402L41.2881 104.716L45.1224 109.977C46.1662 111.409 45.8395 113.336 44.3699 114.409L34.7309 120.783" fill="#86D2F4" />
                <path d="M31.7143 119.402L41.2881 104.716L45.1224 109.977C46.1662 111.409 45.8395 113.336 44.3699 114.409L34.7309 120.783" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M24.3241 103.976C24.3241 103.976 30.741 109.508 39.0888 103.983L24.3241 103.976Z" fill="white" />
                <path d="M24.3241 103.976C24.3241 103.976 30.741 109.508 39.0888 103.983" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M30.7604 121.69L18.7228 114.509C17.2536 113.437 16.9263 111.51 17.97 110.078L21.8049 104.817C21.9525 104.983 35.8631 120.146 35.8653 121.69H30.7604Z" fill="#86D2F4" />
                <path d="M30.7604 121.69L18.7228 114.509C17.2536 113.437 16.9263 111.51 17.97 110.078L21.8049 104.817C21.9525 104.983 35.8631 120.146 35.8653 121.69H30.7604Z" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22.9747 61.0799C21.7124 61.356 20.4497 61.6916 19.1867 62.0869L21.0789 49.3382C21.2791 47.9898 22.5346 46.9848 24.0184 46.9848H39.4105C40.894 46.9848 42.1497 47.9898 42.3496 49.3382L44.2417 62.0869C38.4949 60.2842 32.7414 59.7221 26.9808 60.4048" fill="white" />
                <path d="M22.9747 61.0799C21.7124 61.356 20.4497 61.6916 19.1867 62.0869L21.0789 49.3382C21.2791 47.9898 22.5346 46.9848 24.0184 46.9848H39.4105C40.894 46.9848 42.1497 47.9898 42.3496 49.3382L44.2417 62.0869C38.4949 60.2842 32.7414 59.7221 26.9808 60.4048" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M119.931 109.394V132.246C119.931 133.91 121.228 135.258 122.827 135.258C124.425 135.258 125.721 133.91 125.721 132.246V109.291L119.931 109.394Z" fill="white" />
                <path d="M119.931 109.394V132.246C119.931 133.91 121.228 135.258 122.827 135.258C124.425 135.258 125.721 133.91 125.721 132.246V109.291L119.931 109.394Z" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M101.88 79.8109C102.087 79.2982 102.312 78.7944 102.553 78.3007C105.346 72.6243 110.465 68.302 116.676 66.585C118.591 66.0514 120.604 65.7633 122.682 65.7633C135.064 65.7633 145.096 75.8039 145.096 88.1852C145.096 99.7696 136.313 109.308 125.032 110.483C124.26 110.565 123.479 110.607 122.682 110.607C122.115 110.607 121.549 110.582 120.998 110.532C117.975 110.319 115.124 109.497 112.552 108.183C107.573 105.669 103.671 101.339 101.699 96.0723C101.542 95.67 101.403 95.2753 101.28 94.8648C101.132 94.3965 101.001 93.92 100.885 93.4352C100.475 91.7506 100.261 89.993 100.261 88.1852C100.261 85.2233 100.839 82.3984 101.88 79.8109Z" fill="white" />
                <path d="M145.54 87.9797C145.54 100.485 135.403 110.621 122.899 110.621C110.394 110.621 100.257 100.485 100.257 87.9797C100.257 75.4753 110.394 65.3382 122.899 65.3382C135.403 65.3382 145.54 75.4753 145.54 87.9797Z" fill="#F19775" />
                <path d="M100.331 89.9727C100.425 91.1548 100.611 92.3115 100.885 93.4352C101.001 93.92 101.132 94.3965 101.28 94.8648C101.403 95.2753 101.542 95.67 101.69 96.0641C103.671 101.339 107.573 105.669 112.552 108.183C115.124 109.497 117.975 110.319 120.998 110.532C121.549 110.582 122.115 110.607 122.682 110.607C123.479 110.607 124.26 110.565 125.032 110.483C136.313 109.308 145.096 99.7696 145.096 88.1852C145.096 75.8039 135.064 65.7633 122.682 65.7633C120.604 65.7633 118.591 66.0514 116.676 66.585C110.465 68.302 105.346 72.6243 102.553 78.3007C102.312 78.7944 102.087 79.2982 101.88 79.8109C101.88 79.8109 101.148 81.8686 101.003 82.4543" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M138.871 88.4055C138.871 97.462 131.529 104.803 122.473 104.803C113.417 104.803 106.075 97.462 106.075 88.4055C106.075 79.349 113.417 72.0077 122.473 72.0077C131.529 72.0077 138.871 79.349 138.871 88.4055Z" fill="white" />
                <path d="M107.762 80.8592C109.833 76.6517 113.626 73.4486 118.23 72.1758C119.649 71.7805 121.141 71.5673 122.682 71.5673C131.859 71.5673 139.293 79.0083 139.293 88.1853C139.293 96.7716 132.784 103.841 124.423 104.712C123.85 104.773 123.272 104.803 122.682 104.803C122.261 104.803 121.841 104.785 121.433 104.749C119.192 104.59 117.079 103.981 115.173 103.007C111.483 101.143 108.591 97.934 107.129 94.0311C107.013 93.7323 106.91 93.4404 106.819 93.1358C106.709 92.7887 106.611 92.4353 106.526 92.0762" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M102.994 127.536V129.572C102.994 133.074 99.9809 135.923 96.2779 135.923H78.6935C75.019 135.923 72.0298 133.096 72.0298 129.621C72.0298 126.146 75.019 123.319 78.6935 123.319H80.3166C84.592 123.319 88.0704 120.03 88.0704 115.987C88.0704 111.944 84.592 108.654 80.3166 108.654H77.9251" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M114.021 106.466C116.062 100.133 116.957 94.7513 117.112 88.4677C117.139 87.3389 116.223 86.4119 115.094 86.4119H112.707" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M105.144 119.312V125.239C105.144 126.308 104.277 126.503 103.207 126.503H102.989H102.771C101.701 126.503 100.834 126.308 100.834 125.239C100.834 125.239 100.834 119.365 100.834 119.365C97.5139 118.698 93.5291 116.115 90.4955 108.645C90.0234 107.485 90.9397 106.878 92.0989 106.407C93.2595 105.935 94.2246 105.779 94.6947 106.94C96.8749 112.306 99.6015 115.027 102.801 115.027C106.315 115.027 109.166 112.313 111.275 106.962C111.733 105.798 112.692 105.938 113.857 106.398C115.022 106.857 115.95 107.459 115.492 108.623C112.614 115.927 108.598 118.568 105.144 119.313V119.312Z" fill="#F5D065" />
                <path d="M105.144 119.312V125.239C105.144 126.308 104.277 126.503 103.207 126.503H102.989H102.771C101.701 126.503 100.834 126.308 100.834 125.239C100.834 125.239 100.834 119.365 100.834 119.365C97.5139 118.698 93.5291 116.115 90.4955 108.645C90.0234 107.485 90.9397 106.878 92.0989 106.407C93.2595 105.935 94.2246 105.779 94.6947 106.94C96.8749 112.306 99.6015 115.027 102.801 115.027C106.315 115.027 109.166 112.313 111.275 106.962C111.733 105.798 112.692 105.938 113.857 106.398C115.022 106.857 115.95 107.459 115.492 108.623C112.614 115.927 108.598 118.568 105.144 119.313V119.312Z" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M91.9574 106.466C89.9162 100.133 89.0215 94.7513 88.8673 88.4677C88.84 87.3389 89.7556 86.4119 90.8851 86.4119H93.2715" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M69.3128 108.968C69.3128 111.512 71.375 113.574 73.9182 113.574C76.4619 113.574 78.5241 111.512 78.5241 108.968C78.5241 106.425 76.4619 104.363 73.9182 104.363C71.375 104.363 69.3128 106.425 69.3128 108.968Z" fill="#86D2F4" />
                <path d="M69.3128 108.968C69.3128 111.512 71.375 113.574 73.9182 113.574C76.4619 113.574 78.5241 111.512 78.5241 108.968C78.5241 106.425 76.4619 104.363 73.9182 104.363C71.375 104.363 69.3128 106.425 69.3128 108.968Z" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M110.326 87.8623V89.7621H109.727C107.876 89.7621 106.376 88.2621 106.376 86.4118C106.376 84.5622 107.876 83.0622 109.727 83.0622H110.326V84.1485" fill="white" />
                <path d="M110.326 87.8623V89.7621H109.727C107.876 89.7621 106.376 88.2621 106.376 86.4118C106.376 84.5622 107.876 83.0622 109.727 83.0622H110.326V84.1485" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M95.653 88.5311V89.7621H96.252C98.1022 89.7621 99.6022 88.2621 99.6022 86.4118C99.6022 84.5622 98.1022 83.0622 96.252 83.0622H95.653V84.3439" fill="white" />
                <path d="M95.653 88.5311V89.7621H96.252C98.1022 89.7621 99.6022 88.2621 99.6022 86.4118C99.6022 84.5622 98.1022 83.0622 96.252 83.0622H95.653V84.3439" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M109.726 87.8623H112.108C112.589 87.8623 112.978 87.4727 112.978 86.9924V85.8318C112.978 85.3515 112.589 84.9619 112.108 84.9619H109.726" fill="white" />
                <path d="M109.726 87.8623H112.108C112.589 87.8623 112.978 87.4727 112.978 86.9924V85.8318C112.978 85.3515 112.589 84.9619 112.108 84.9619H109.726" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M96.2531 87.8623H93.8712C93.3902 87.8623 93.0006 87.4727 93.0006 86.9924V85.8318C93.0006 85.3515 93.3902 84.9619 93.8712 84.9619H96.2531" fill="white" />
                <path d="M96.2531 87.8623H93.8712C93.3902 87.8623 93.0006 87.4727 93.0006 86.9924V85.8318C93.0006 85.3515 93.3902 84.9619 93.8712 84.9619H96.2531" stroke="#441649" stroke-width="0.947277" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_9816_15655">
                    <rect width="150" height="145.907" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
