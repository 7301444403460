export function PlaceholderIcon() {
  return (
    <svg
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.17798 16H14.178V18H7.17798V16ZM7.17798 12H17.178V14H7.17798V12ZM7.17798 8H17.178V10H7.17798V8ZM19.178 4H14.998C14.578 2.84 13.478 2 12.178 2C10.878 2 9.77798 2.84 9.35798 4H5.17798C5.03798 4 4.90798 4.01 4.77798 4.04C4.38798 4.12 4.03798 4.32 3.76798 4.59C3.58798 4.77 3.43798 4.99 3.33798 5.23C3.23798 5.46 3.17798 5.72 3.17798 6V20C3.17798 20.27 3.23798 20.54 3.33798 20.78C3.43798 21.02 3.58798 21.23 3.76798 21.42C4.03798 21.69 4.38798 21.89 4.77798 21.97C4.90798 21.99 5.03798 22 5.17798 22H19.178C20.278 22 21.178 21.1 21.178 20V6C21.178 4.9 20.278 4 19.178 4ZM12.178 3.75C12.588 3.75 12.928 4.09 12.928 4.5C12.928 4.91 12.588 5.25 12.178 5.25C11.768 5.25 11.428 4.91 11.428 4.5C11.428 4.09 11.768 3.75 12.178 3.75ZM19.178 20H5.17798V6H19.178V20Z"
        fill="black"
      />
    </svg>
  );
}
