import { SPECIALIST_HOSTNAME } from "env-vars";

export async function requestPasswordReset(
  email: string,
  surveyKind?: string
) {
  return fetch(`${SPECIALIST_HOSTNAME}/api/v1/forgot_password/email`, {
    method: "POST",
    body: JSON.stringify({
      email: email,
      survey_kind: surveyKind,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
