import { useState, useCallback, useEffect } from "react";
import { useAdminContent, useFeatureFlags } from "context/admin-content";
import { Loading } from "components/loading";
import { Video } from "components/video";
import { NameDobCodeModal } from "../name-dob-code-modal";
import { clearQuestionnaire, openQuestionnaire } from "helpers";
import {
  Button,
  Heading,
  Container,
  ErrorBanner,
  MainContent,
  LeftColumn,
  RightColumn,
  GlobalStyles,
  ProviderImage,
  LogoContainer,
  HeaderContent,
  VideoContainer,
  InnerContainer,
} from "./styles";

export function KioskLandingPage() {
  const { disableSurveyCodeForKiosk, startSurveyViaCode } = useFeatureFlags();
  const adminData = useAdminContent();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [noSurveyError, setSurveyError] = useState<boolean>(false);
  const isLoading = adminData?.isLoading;
  const accountType = adminData?.accountType;
  const locationName = adminData?.locationName;
  const initialSurveyToken = adminData?.initialSurveyToken;

  const onCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleStartClick = useCallback(async () => {
    clearQuestionnaire();

    if (startSurveyViaCode) {
      setShowModal(true);
    } else {
      const currentUrl = document.location.href.split("?")[0];
      const redirectUrl = `${currentUrl}?complete=true`;
      const queryParams = {
        redirectUrl: encodeURIComponent(redirectUrl),
        restartUrl: encodeURIComponent(currentUrl),
      };

      if (initialSurveyToken) {
        openQuestionnaire({
          queryParams,
          newTab: false,
          context: "kiosk",
          token: initialSurveyToken,
          skipLandingPage: true,
        });
      } else {
        setSurveyError(true);
      }
    }
  }, [initialSurveyToken, startSurveyViaCode]);

  useEffect(() => {
    // Zendesk should not be enabled in the Kiosk workflow
    // This prevents it appearing in Questionnaire.
    localStorage.setItem("disableZendesk", "true");
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const pageTitle = adminData?.landing.pageTitle;
  const imageUrl = adminData?.imageUrl;
  const disclaimer = adminData?.landing.disclaimer;
  const videoUrl = adminData?.landing.videoUrl;
  const videoEnabled = adminData?.landing.videoEnabled;
  const zendeskEnabled = adminData?.featureFlags?.zendesk_enabled;

  return (
    <Container>
      <LogoContainer>
        <img
          alt="Powered by"
          id="powered-by"
          src={`https://assets.canceriq.com/account_type/${accountType}/logos/standard.png`}
        />
      </LogoContainer>
      {noSurveyError && (
        <ErrorBanner>Survey not found for location {locationName}</ErrorBanner>
      )}
      <InnerContainer className="start-assessment">
        <GlobalStyles />
        <HeaderContent>
          <ProviderImage alt={pageTitle} src={imageUrl} />
          <Heading>{pageTitle}</Heading>
        </HeaderContent>
        <MainContent $videoEnabled={videoEnabled} data-id="MainContent">
          {videoEnabled && (
            <LeftColumn data-id="LeftColumn">
              <VideoContainer
                $zendeskEnabled={zendeskEnabled}
                data-id="VideoContainer"
              >
                <Video src={videoUrl} />
              </VideoContainer>
            </LeftColumn>
          )}
          <RightColumn>
            <p>{disclaimer}</p>
            <Button type="button" onClick={handleStartClick} disabled={false}>
              Start Assessment
            </Button>
          </RightColumn>
        </MainContent>
      </InnerContainer>
      {showModal && (
        <NameDobCodeModal
          onClose={onCloseModal}
          disableCodePrompt={disableSurveyCodeForKiosk === true}
        />
      )}
    </Container>
  );
}
