import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  ReactElement,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
// @ts-ignore - auth0-js is not typed
import { default as auth0 } from "auth0-js";
import { getProtocolHost } from "helpers";
import { jwtDecode, JwtPayload } from "jwt-decode";
import {
  AUTH0_DOMAIN,
  AUTH0_PATIENTS_CLIENT_ID,
  AUTH0_PATIENTS_CONNECTION,
} from "env-vars";
import { requestPasswordReset } from "helpers/request-password-reset";
import * as Sentry from "@sentry/react";
import { SurveyKind } from "../survey";
import { Span } from "@sentry/tracing";

const webAuth = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_PATIENTS_CLIENT_ID,
  redirectUri: getProtocolHost(),
  responseType: "token id_token",
  scope: "openid profile email",
});

enum EmailLinkError {
  GENERAL_ERROR = "general",
  UNAUTHORIZED = "unauthorized",
  NO_LOCATION_ID = "no_location_id",
  INCORRECT_LOCATION_ID = "incorrect_location_id"
}

enum EmailPasswordError {
  ACCESS_DENIED = "access_denied",
  GENERAL_ERROR = "general",
}

// eslint-disable-next-line react-refresh/only-export-components
export const emailLinkErrorMessage: Record<EmailLinkError, string> = {
  [EmailLinkError.GENERAL_ERROR]:
    "There was an error signing you in. Please try again.",
  [EmailLinkError.UNAUTHORIZED]:
    "The link has expired after 15 minutes or has already been used. Please enter your email to receive a new link.",
  [EmailLinkError.NO_LOCATION_ID]:
    "Your account is missing a sign-up location. Please use the link you received to create your account, or reach out to your health system for support.",
  [EmailLinkError.INCORRECT_LOCATION_ID]:
    "Incorrect account sign-up location. Please reach out to your health system for support.",
};

// eslint-disable-next-line react-refresh/only-export-components
export const emailPasswordErrorMsg: Record<EmailPasswordError, string> = {
  [EmailPasswordError.ACCESS_DENIED]:
    "Invalid email or password. Please try again.",
  [EmailPasswordError.GENERAL_ERROR]:
    "Error submitting credentials. Please try again.",
};

export type PatientAuthContextType = {
  isLoading: boolean;
  isEmailSent: boolean;
  isEmailResent: boolean;
  isSubmitting: boolean;
  emailAddress: string;
  isAuthenticated: boolean;
  passwordlessError: boolean;
  emailLinkError: EmailLinkError | undefined;
  emailPasswordError: EmailPasswordError | undefined;
  forgotPasswordError: boolean;
  logout: () => void;
  setSubmitting: (value: boolean) => void;
  sendEmailWithCode: (email: string, resend?: boolean) => void;
  setPasswordlessError: (value: boolean) => void;
  setEmailLinkError: (value: EmailLinkError | undefined) => void;
  setEmailPasswordError: (value: EmailPasswordError | undefined) => void;
  setForgotPasswordError: (value: boolean) => void;
  submitCredentials: (email: string, password: string) => void;
  requestForgotPasswordReset: (email: string) => void;
  verifyLoginCode: (code: string) => void;
};

export const PatientAuthContext = createContext<
  PatientAuthContextType | undefined
>(undefined);

const logError = (message: string, err: unknown) => {
  Sentry.captureMessage(message, { extra: { err } });
};

type AuthResult = {
  idToken: string;
  idTokenPayload: {
    app_metadata: {
      location_id: string;
      ciq_id: string;
    };
    email: string;
  };
  appState: string;
};

type Props = {
  children: ReactElement;
};

// for params that need to be preserved through auth0 login redirect
type LaunchState = {
  surveyKind?: SurveyKind;
  qs_location_id: string;
};

function getLaunchState(): LaunchState {
  const urlParams = new URLSearchParams(window.location.search);
  
  return {
    // surveyKind overrides the default initial/rescreen survey logic
    surveyKind: urlParams.get("kind") as SurveyKind,
    // qs_location_id is only used for new users
    qs_location_id: urlParams.get("location_id") as string,
  };
}

function encodeLaunchState(): string {
  const state = getLaunchState();
  return btoa(JSON.stringify(state));
}

function decodeLaunchState(state: string): LaunchState | undefined {
  return state ? (JSON.parse(atob(state)) as LaunchState) : undefined;
}

export function PatientAuthProvider({ children }: Props) {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [isEmailSent, setEmailSent] = useState(false);
  const [isEmailResent, setEmailResent] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [passwordlessError, setPasswordlessError] = useState(false);
  const [emailPasswordError, setEmailPasswordError] = useState<
    EmailPasswordError | undefined
  >(undefined);
  const [emailLinkError, setEmailLinkError] = useState<
    EmailLinkError | undefined
  >(undefined);
  const [forgotPasswordError, setForgotPasswordError] = useState(false);

  const handleTokenAndRedirect = useCallback((hadLaunchLocation: boolean) => {
    const idTokenExp = localStorage.getItem("id_token_exp");
    const idToken = localStorage.getItem("id_token");

    const isTokenValid =
      Boolean(idToken) &&
      Boolean(idTokenExp) &&
      Number(idTokenExp) > Date.now();

    setAuthenticated(isTokenValid);

    if (!isTokenValid) {
      localStorage.clear();
      setLoading(false);

      return;
    }

    const locationId = localStorage.getItem("location_id");
    const ciqId = localStorage.getItem("ciq_id");

    if (!locationId) {
      setEmailLinkError(hadLaunchLocation ? EmailLinkError.INCORRECT_LOCATION_ID : EmailLinkError.NO_LOCATION_ID);
      localStorage.clear();
      setAuthenticated(false);
      setLoading(false);

      history.push("/login/passwordless");

      return;
    }

    if (!ciqId) {
      history.push("/login/info");
    }

    setLoading(false);
  }, [history]);

  useEffect(() => {
    webAuth.parseHash((error: unknown, authResult: AuthResult) => {
      // This code runs on page load. Only record a transaction if there is a login result.
      let transaction: Span | undefined = undefined;
      if (error || authResult) {
        transaction = Sentry.startTransaction({ name: "Process AuthResult" });
        Sentry.getCurrentHub().configureScope((scope) =>
          scope.setSpan(transaction)
        );
      }

      if (error) {
        // @ts-ignore no types
        const errorType = error.error;
        // @ts-ignore no types
        const errorDesc = error.errorDescription;

        if (
          errorType === EmailLinkError.UNAUTHORIZED &&
          errorDesc.includes("Wrong email or verification code")
        ) {
          setEmailLinkError(EmailLinkError.UNAUTHORIZED);
        } else {
          setEmailLinkError(EmailLinkError.GENERAL_ERROR);
        }

        console.error(
          `Error from parseHash. error: "${errorType}", desc: "${errorDesc}"`
        );
        localStorage.clear();
        setAuthenticated(false);
        setLoading(false);

        transaction?.setStatus("unauthenticated");
        transaction?.finish();

        return;
      }

      const idToken = authResult?.idToken;
      const idTokenPayload = authResult?.idTokenPayload;
      let hadLaunchLocation = false;

      if (idToken && idTokenPayload) {
        localStorage.setItem("id_token", authResult.idToken);

        const { exp = 0 } = jwtDecode<JwtPayload>(idToken);

        const calculatedExpiry = (exp * 1000).toString();

        localStorage.setItem("id_token_exp", calculatedExpiry);

        const appMetaData = authResult?.idTokenPayload?.app_metadata;
        const email = authResult?.idTokenPayload.email;
        const locationId = appMetaData?.location_id;
        const ciqId = appMetaData?.ciq_id;

        if (locationId) {
          localStorage.setItem("location_id", locationId);
        }
        if (ciqId) {
          localStorage.setItem("ciq_id", ciqId);
        }
        if (email) {
          localStorage.setItem("ciq_email", email);
        }

        const launchState = decodeLaunchState(authResult?.appState);
        const launchSurveyKind = launchState?.surveyKind;
        hadLaunchLocation = Boolean(launchState?.qs_location_id);

        if (launchSurveyKind) {
          localStorage.setItem("launch_survey_kind", String(launchSurveyKind));
        }

        // Clears hash in URL produced by Auth0 redirect
        if (window.location.hash) {
          history.replace({
            pathname: location.pathname,
            search: location.search,
          });
        }

        transaction?.setStatus("ok");
        transaction?.finish();
      }

      handleTokenAndRedirect(hadLaunchLocation);
    });
  }, [history, location.search, location.pathname, handleTokenAndRedirect]);

  const sendEmailWithCode = useCallback(
    (email: string, resend = false) => {
      setEmailSent(false);
      setEmailResent(false);

      webAuth.passwordlessStart(
        {
          connection: "email",
          send: "code",
          email: email,
          authParams: {
            // state is used by auth0 actions for new user sign-up
            state: encodeLaunchState(),
            // appState is used by this client
            appState: encodeLaunchState(),
          },
        },
        function (err: any) {
          setPasswordlessError(Boolean(err));
          setEmailSent(true);
          setSubmitting(false);

          if (resend) {
            setEmailResent(true);
          }

          if (err) {
            logError("Passwordless start error", err);

            if (err.code === "access_denied") {
              setEmailLinkError(EmailLinkError.UNAUTHORIZED);
            } else {
              setEmailLinkError(EmailLinkError.GENERAL_ERROR);
            }
          } else {
            setEmailAddress(email);

            history.push({
              pathname: "/login/passwordless/confirm",
              search: location.search,
            });
          }
        }
      );
    },
    [location, history]
  );

  const verifyLoginCode = useCallback(
    (code: string) => {
      webAuth.passwordlessLogin(
        {
          connection: "email",
          email: emailAddress,
          verificationCode: code,
          // state is used by auth0 actions for new user sign-up
          state: encodeLaunchState(),
          // appState is used by this client
          appState: encodeLaunchState(),
        },
        function (err: Error, res: unknown) {
          if (err) {
            console.error("Code verification failed", err);
            setEmailLinkError(EmailLinkError.UNAUTHORIZED);
          }
          if (res) {
            window.location.assign("/");
          }
        }
      );
    },
    [emailAddress]
  );

  const submitCredentials = useCallback((email: string, password: string) => {
    webAuth.login(
      {
        realm: AUTH0_PATIENTS_CONNECTION,
        email: email,
        password: password,
        // state is not included here since there is no new user sign-up flow w/password login
        // appState is used by this client
        appState: encodeLaunchState(),
      },
      function (err: Error) {
        if (err) {
          setSubmitting(false);
          // handle login error result
          // @ts-ignore no error types
          if (err.code === EmailPasswordError.ACCESS_DENIED) {
            setEmailPasswordError(EmailPasswordError.ACCESS_DENIED);
          } else {
            setEmailPasswordError(EmailPasswordError.GENERAL_ERROR);
            logError("Login error", err);
          }
        } else {
          // login should redirect to the auth0 and then back to the app
          console.debug("expected redirect, but nothing happened");
        }
      }
    );
  }, []);

  const logout = useCallback(() => {
    localStorage.clear();

    // End auth0 session. this doesn't affect app behavior, just here for completeness
    webAuth.logout();
  }, []);

  const requestForgotPasswordReset = useCallback(async (email: string) => {
      setSubmitting(true);
      setEmailSent(false);

      const { surveyKind } = getLaunchState();
      const response = await requestPasswordReset(email, surveyKind);

      setSubmitting(false);
      setEmailSent(true);

      if (!response.ok) {
        setForgotPasswordError(true);
        logError("Error requesting password reset", response);
        return;
      } 
      
      setForgotPasswordError(false);
      setEmailAddress(email);
      history.push("/login/forgot/confirm");
    },
    [history, setEmailAddress, setForgotPasswordError, setSubmitting]
  );

  const contextProps = useMemo(
    () => ({
      logout,
      isLoading,
      isEmailSent,
      isEmailResent,
      isSubmitting,
      emailAddress,
      setSubmitting,
      emailLinkError,
      verifyLoginCode,
      sendEmailWithCode,
      submitCredentials,
      isAuthenticated,
      passwordlessError,
      emailPasswordError,
      setEmailLinkError,
      setPasswordlessError,
      setEmailPasswordError,
      requestForgotPasswordReset,
      forgotPasswordError,
      setForgotPasswordError,
    }),
    [
      logout,
      isLoading,
      isEmailSent,
      isEmailResent,
      emailAddress,
      isSubmitting,
      setSubmitting,
      emailLinkError,
      verifyLoginCode,
      submitCredentials,
      isAuthenticated,
      sendEmailWithCode,
      passwordlessError,
      emailPasswordError,
      requestForgotPasswordReset,
      forgotPasswordError,
      setForgotPasswordError,
    ]
  );

  return (
    <PatientAuthContext.Provider value={contextProps}>
      {children}
    </PatientAuthContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const usePatientAuth = () => {
  const context = useContext(PatientAuthContext);

  if (context === undefined) {
    throw new Error("usePatientAuth must be used within a PatientAuthProvider");
  }
  return context;
};
