import {
  useMemo,
  useState,
  useEffect,
  useContext,
  ReactElement,
  createContext,
} from "react";
import { ErrorType, SPECIALIST_HOSTNAME } from "env-vars";
import { getAdminData } from "./admin-data-helper";
import { useAuthenticated } from "hooks/use-authenticated";
import { AdminContentContextType } from "./types";

const AdminContentContext = createContext<AdminContentContextType | null>(null);

type Props = {
  children: ReactElement;
};

export function AdminContentProvider({ children }: Props) {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ErrorType | null>(null);
  const [data, setData] = useState<AdminContentContextType | null>(null);
  const { isAuthenticated } = useAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) return;

    (async () => {
      const idToken = localStorage.getItem("id_token");

      try {
        const response = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/institutions/info`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (!response.ok) {
          console.error("Error fetching institution info");

          setLoading(false);
          setError(
            response.status === 401 ? ErrorType.AUTH : ErrorType.GENERAL
          );

          return;
        }

        const adminData = await response.json();

        setData({
          ...getAdminData(adminData),
          isLoading: false,
          error: null,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching institution info: ", error);
        setError(ErrorType.GENERAL);
        setLoading(false);
      }
    })();
  }, [isAuthenticated]);

  const contextProps = useMemo(
    () => ({
      isLoading,
      error,
      ...(data || {}),
    }),
    [data, isLoading, error]
  );

  return (
    <AdminContentContext.Provider
      value={contextProps as AdminContentContextType}
    >
      {children}
    </AdminContentContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const useAdminContent = () => {
  const context = useContext(AdminContentContext);

  if (context === undefined) {
    throw new Error(
      "useAdminContent must be used within an AdminContentProvider"
    );
  }
  return context;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useFeatureFlags = () => {
  const adminContent = useAdminContent();

  if (!adminContent?.featureFlags) {
    return {};
  }

  const { featureFlags } = adminContent;

  return {
    disableSurveyCodeForKiosk: featureFlags.disable_survey_code_for_kiosk,
    startSurveyViaCode: featureFlags.start_survey_via_code,
    disablePatientApprovalForRemoteScreenings:
      featureFlags.disable_patient_approval_for_remote_screenings,
    // CDP campaign flag
    campaignContentEnabled: featureFlags.campaign_content_enabled,
    // Populus advert flag
    targetedPatientContent: featureFlags.targeted_patient_content,
    enableRescreenAndResults: featureFlags.enable_rescreen_and_view_results_in_patient_portal,
  };
};
